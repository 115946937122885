<template>
  <v-container fluid class="pa-0 ma-0">
    <v-container fluid class="content">
      <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
        <strong>Ficha de Gastos</strong>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <small v-if="missingFields" class="font-weight-light red--text mr-1">Es necesario llenar todos los campos de los Proyectos Operativos para activar la opción. </small>
          <v-btn
            v-if="project && !project.aprobadoDGE"
            :color="items.length === 0 ? 'green' : 'secondary-color'"
            class="primary-text--text"
            elevation="0"
            :disabled="missingFields"
            :to="project != null ? ('/ficha-plan-operativo/proyecto/' + project.id + '/gastos/crear') : ''">
            <small><v-icon left small>mdi-pencil</v-icon>gastos</small>
          </v-btn>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-card
        elevation="0">
        <v-col cols="12" class="w-full pa-0 ma-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="4">
              <strong>Ítem de gasto</strong>
            </v-col>
            <v-col cols="4">
              <strong>Descripción del gasto</strong>
            </v-col>
            <v-col cols="4">
              <strong>Monto</strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="items.length == 0" class="ma-0 pa-0">
            <v-col cols="3">
              Sin Gastos
            </v-col>
          </v-row>
          <v-row align="center" v-for="(item, itemIndex) in items" :key="itemIndex" class="ma-0 pa-0">
            <v-col cols="4">
              <select-component
                placeholder="Ítem"
                :disabled="true"
                :must="['required']"
                :items="options"
                :hide_details="true"
                icon="mdi-arrow-bottom-right-thin-circle-outline"
                :default="items[itemIndex].nombre"
                @value="(value) => {items[itemIndex].nombre = value}"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                filled
                name="input-7-4"
                label="Descripción"
                color="primary-color"
                no-resize
                v-model="items[itemIndex].descripcion"
                readonly
                counter
                :maxlength="300"
                :rules="[value => value.length <= 300 || 'Maximo 300 caracteres', value => !!value || 'Requerido.']"
              ></v-textarea>
            </v-col>
            <v-col cols="4">
              <input-text
                :placeholder="formatMoney(items[itemIndex].monto)"
                :must="['required']"
                @text="(value) => { items[itemIndex].monto = value }"
                :value="items[itemIndex].monto"
                :disabled="true"
                :type="'number'"
                :hide_details="true"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import InputText from '@/components/form/InputText.vue';
import Select from '@/components/form/Select.vue';

export default {
  name: 'expense-tab',
  props: {
    project: {
      type: Object,
    },
    expenses: {
      type: Array
    },
    missingFields: {
      type: Boolean
    }
  },
  components: {
    'input-text': InputText,
    'select-component': Select,
  },
  data() {
    return {
      localMissingFields: this.missingFields,
      items: this.expenses,
      options: [
        'Honorarios',
        'Becas estudiantiles',
        'Becas estudiantiles (Beca de trabajo, Beca de movilidad o Beca de ayuda económica)',
        'Bienes y equipos',
        'Materiales e Insumos de laboratorios',
        'Mobiliario',
        'Becas de movilidad',
        'Catering',
        'Servicio de Catering o Coffee Break',
        'Visita académica',
        'Estadía académica',
        'Conferencista',
        'Arriendo Espacios',
        'Arriendo',
        'Software',
        'Servicios Difusión',
        'Servicios',
        'Servicios y productos de difusión',
        'Merchandising',
        'Estadías académicos',
        'Equipos Computacionales',
        'Importaciones o Compras Internacionales',
        'Otros'
      ]
    }
  },
  methods: {
    formatMoney(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
      });

      return formatter.format(value);
    }
  },
  watch: {
    expenses() {
      this.items = this.expenses;
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>

<style scoped>
.content {
  background-color: white;
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.content-footer {
  position: relative;
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.dropzone-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square-background {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 12px;
}
.web-grid div::before,
.web-grid-profile div::before {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.border {
  border: 1px solid #dedede;
  width: 100%;
}
</style>