<template>
  <v-container fluid class="pa-0 ma-0">
    <v-container fluid class="content">
      <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
        <strong>Hitos de la Ficha de Plan Operativo</strong>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <small v-if="missingFields" class="font-weight-light red--text mr-1">Es necesario llenar todos los campos de los Proyectos Operativos para activar la opción.</small>
          <v-btn
            v-if="project && !project.aprobadoDGE"
            :color="items.length == 0 ? 'green' : 'secondary-color'"
            class="primary-text--text"
            elevation="0"
            :disabled="missingFields"
            :to="project != null ? ('/ficha-plan-operativo/proyecto/' + project.id + '/hitos/crear') : ''">
            <small><v-icon left small>mdi-pencil</v-icon>hitos</small>
          </v-btn>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-card elevation="0">
        <v-col cols="12" class="w-full pa-0 ma-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="2">
              <strong>Hito</strong>
            </v-col>
            <v-col cols="4">
              <strong>Actividades</strong>
            </v-col>
            <v-col cols="3">
              <strong>Mes Inicio</strong>
            </v-col>
            <v-col cols="3">
              <strong>Mes de Término</strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="items.length == 0" class="ma-0 pa-0">
            <v-col cols="3">
              Sin Hitos
            </v-col>
          </v-row>
          <v-row align="center" v-for="(item, itemIndex) in items" :key="itemIndex" class="ma-0 pa-0">
            <v-col cols="2">
              <v-textarea
                filled
                name="input-7-4"
                label="Nombre"
                color="primary-color"
                no-resize
                v-model="items[itemIndex].nombre"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="4">
              <v-textarea
                filled
                name="input-7-4"
                label="Actividades"
                color="primary-color"
                no-resize
                v-model="items[itemIndex].actividad"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="3">
              <select-component
                :items="months"
                itemtext="name"
                @value="(value) => { items[itemIndex].mes_inicio = value; }"
                :default="items[itemIndex].mes_inicio"
                :disabled="true"
              />
            </v-col>
            <v-col cols="3">
              <select-component
                :items="months"
                itemtext="name"
                @value="(value) => { items[itemIndex].mes_termino = value; }"
                :default="items[itemIndex].mes_termino"
                :disabled="true"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import Select from '@/components/form/Select.vue';

export default {
  name: 'application-form-milestones',
  props: {
    project: {
      type: Object,
    },
    milestones: {
      type: Array
    },
    missingFields: {
      type: Boolean
    }
  },
  components: {
    'select-component': Select
  },
  data() {
    return {
      items: this.milestones,
      months: [
        {
          name: 'Seleccione',
          number: 0,
        },
        {
          name: 'Enero',
          number: 1,
        },
        {
          name: 'Febrero',
          number: 2,
        },
        {
          name: 'Marzo',
          number: 3,
        },
        {
          name: 'Abril',
          number: 4,
        },
        {
          name: 'Mayo',
          number: 5,
        },
        {
          name: 'Junio',
          number: 6,
        },
        {
          name: 'Julio',
          number: 7,
        },
        {
          name: 'Agosto',
          number: 8,
        },
        {
          name: 'Septiembre',
          number: 9,
        },
        {
          name: 'Octubre',
          number: 10,
        },
        {
          name: 'Noviembre',
          number: 11,
        },
        {
          name: 'Diciembre',
          number: 12,
        },
      ]
    }
  },
  watch: {
    milestones() {
      this.items = this.milestones;
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>

<style scoped>
.content {
  background-color: white;
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.content-footer {
  position: relative;
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.dropzone-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square-background {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 12px;
}
.web-grid div::before,
.web-grid-profile div::before {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.border {
  border: 1px solid #dedede;
  width: 100%;
}
</style>