<template>
  <div>
    <div class="d-print-none pt-15 my-10 main-component-content">
      <steps-component/>
      <v-row class="ma-0 pa-0">
        <v-col
            cols="12">
          <v-container>
            <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
              <h1 class="text-left w-full mb-2">Proyectos Plan Operativo</h1>
              <p class="text-left w-full">
                En relación a su plan estratégico disciplinar, la Unidad podrá desarrollar diversos proyectos / iniciativas /
                acciones orientada a su cumplimiento. Por lo anterior, la unidad dispone de recursos para contribuir en su
                desarrollo entendiendo que no todos los HITOS de su proyecto, requieren necesariamente financiamiento. Por
                lo anterior, su unidad deberá ingresar en la plataforma todos los hitos que contribuirán en el desarrollo de
                su plan anual.
              </p>
              <v-row class="ma-0 mb-6 pa-0">
                <v-col align-self="center" class="ma-0 pa-0"
                       cols="12"
                       xs="12"
                       sm="12"
                       md="12"
                       lg="12"
                       xl="12"
                >
                  <select-component
                      class="mt-3"
                      placeholder="Selecciona un Proyecto"
                      :items="projects"
                      itemtext="nombre"
                      icon="mdi-arrow-bottom-right-thin-circle-outline"
                      :default="project"
                      @value="(value) => { changeProject(value) }"
                  />
                </v-col>
                <v-col align-self="center" class="ma-0 pa-0"
                       cols="12"
                       xs="12"
                       sm="12"
                       md="12"
                       lg="12"
                       xl="12"
                >
                  <v-btn
                      color="primary-color"
                      class="primary-text mt-4 mr-2"
                      elevation="0"
                      :loading="loadingAdd"
                      to="ficha-plan-operativo/proyecto/crear"
                  >
                    <v-icon left small>mdi-plus</v-icon> Agregar Proyecto
                  </v-btn>
                  <v-btn
                      color="tertiary-color"
                      class="primary-text--text mt-4 mr-2"
                      elevation="0"
                      :loading="loadingAdd"
                      :to="'/ficha-plan-operativo/proyecto/editar/'+project.id"
                      :disabled="project && project.aprobadoDGE"
                      v-if="project != null"
                  >
                    <v-icon left small>mdi-pencil</v-icon> Editar Proyecto
                  </v-btn>
                  <v-btn
                      :disabled="missingFields"
                      color="secondary-color"
                      class="primary-text--text mt-4 mr-2"
                      elevation="0"
                      v-if="project && !project.enviadoRevision && !missingFields && allFieldsMilestoneComplete && allFieldsItemGastoComplete"
                      :loading="loadingAdd"
                      @click="sentProject()"
                  >
                    <v-icon left small>mdi-send</v-icon> Enviar proyecto a revisión
                  </v-btn>
                  <v-btn
                      color="secondary-color"
                      class="primary-text--text mt-4 mr-2"
                      elevation="0"
                      v-if="project
                      && project.enviadoRevision
                      && fullTokenDecoded
                      && fullTokenDecoded.authorities
                      && fullTokenDecoded.authorities.find(e => e === 'dge') !== undefined"
                      :loading="loadingAdd"
                      @click="() => { aprobacion = !aprobacion }">
                    <span v-show="!aprobacion" @click="downloadFile()">Aprobación DGE</span>
                    <span v-show="aprobacion">Minimizar Aprobación DGE</span>
                  </v-btn>
                  <v-btn
                      color="primary-color"
                      class="primary-text--text mt-4 mr-2"
                      elevation="0"
                      v-if="projects.length !== 0 && fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined"
                      :loading="loadingAdd"
                      @click="sentObservation()"
                  >
                    Guardar Revision DGE
                  </v-btn>
                  <v-btn
                         color="primary"
                         class="primary-text--text mt-4 mr-2"
                         @click="print">Descargar</v-btn>
                </v-col>
                <small class="red--text mt-1 mb-0" v-show="missingFields">
                  Es necesario llenar todos los campos
                  de Proyectos Operativos para
                  acceder a Hitos y Gastos.
                </small>
              </v-row>
              <v-row v-if="projects.length != 0" class="ma-0 mb-6 pa-0">
                <v-textarea
                    filled
                    name="input-7-4"
                    label="Observación del proyecto"
                    color="primary-color"
                    no-resize
                    :counter="fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined"
                    :readonly="!(fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined)"
                    :maxlength="(fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined) ? 300 : null"
                    :rules="(fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined) ? [value => value.length <= 300 || 'Maximo 300 caracteres'] : []"
                    v-model="observation"
                ></v-textarea>
              </v-row>



              <v-row v-if="aprobacion || !(fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined)" class="ma-0 mb-6 pa-0">
                <v-card elevation="0" class="w-full pa-4">
                  <v-row class="pa-0 ma-0" :class="fullTokenDecoded && fullTokenDecoded.authorities && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined ? 'mb-4' : ''" justify="end" align="center">

                    <span v-if="project && project.approvedAt">Fecha de aprobación del proyecto: <small>{{ project.approvedAt }}</small></span>
                    <span v-if="project && project.approvedAt && projectFile.length > 0" class="ml-5">Archivo Actual: {{projectFile[0].filename}}. </span>



                    <v-btn
                        color="secondary-color"
                        class="primary-text--text ml-4"
                        elevation="0"
                        @click="generateDownloadFile()"
                        :disabled="!(project && project.approvedAt && projectFile.length > 0)">
                      Descargar
                    </v-btn>




                  </v-row>

                  <div v-if="fullTokenDecoded
                  && fullTokenDecoded.authorities
                  && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined && !project.approvedAt"  id="dropzone"
                       @click="uploadImages()" @dragover.prevent @drop.prevent @drop="handleFileDrop"
                       class="dropzone-box pa-5 border rounded">
                    <span v-if="files != null && files.length != 0">
                    <div v-for="(file, fileIndex) in files" :key="fileIndex" class="rounded">
                      <span class="p-2" v-if="file.document">
                        {{ file.document.name }}
                      </span>
                    </div>
                  </span>
                    <span class="empty-file-text" v-else ><span>Agregue PDF<br></span>Presione aquí o arrastre el archivo aquí</span>
                    <input @change="imageFunction" ref="inputFile" class="hidden" type="file" name="forms" id="input-user-images" accept=".pdf">
                  </div>
                  <v-btn
                      v-if="fullTokenDecoded
                      && fullTokenDecoded.authorities
                      && fullTokenDecoded.authorities.find(e => e == 'dge') != undefined"
                      color="primary-color"
                      class="primary-text--text mt-4"
                      elevation="0"
                      @click="sendFile()"
                      :disabled="!!(project && project.approvedAt) || !(files != null && files.length != 0)"
                  >
                    Subir archivo
                  </v-btn>
                </v-card>
              </v-row>
              <v-row class="ma-0 mb-6 pa-0">
                <v-col :class="mobile ? 'border-b' : 'border-r'" align-self="center" justify-self="center" class="ma-0 pa-10"
                       cols="12"
                       xs="12"
                       sm="12"
                       md="6"
                       lg="6"
                       xl="6"
                >
                  <h3>Recursos Asignados al Proyecto</h3>
                  <h4>{{ recursos_asignados_proyecto != null ? formatMoney(recursos_asignados_proyecto) : '-' }}</h4>
                  <span>(Total de recursos asignados al proyecto respectivo)</span>
                </v-col>
                <v-col align-self="center" justify-self="center" class="ma-0 pa-10"
                       cols="12"
                       xs="12"
                       sm="12"
                       md="6"
                       lg="6"
                       xl="6"
                >
                  <h3>Financiamiento Asignado</h3>
                  <h4>{{ financiamiento_asignado != null ? formatMoney(financiamiento_asignado) : '-' }}</h4>
                  <span>(Total de recursos asignados a los proyectos ingresados)</span>
                </v-col>
              </v-row>
              <v-container fluid class="pa-0 mb-6 ma-0">
                <v-container fluid class="content">
                  <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
                    <strong>Información del proyecto</strong>
                  </v-row>
                  <v-row v-if="project" justify="space-between" align="start" class="pa-0 ma-0 mb-2">
                    <v-col class="ma-0 pa-0 px-2"
                           align-self="start"
                           cols="12"
                           xs="12"
                           sm="12"
                           md="6"
                           lg="6"
                           xl="6"
                    >
                      <v-divider class="mb-2"></v-divider>
                        <p class="text-start">
                            <small><strong class="mr-2">Fecha Creación:</strong></small>
                            <span>{{ this.formatDate(project.createdAt) }}</span>
                        </p>
                      <p class="text-start">
                        <small><strong class="mr-2">Nombre:</strong></small>
                        <span>{{ project.nombre }}</span>
                      </p>
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start pb-0 mb-0">
                        <small><strong>Estrategias</strong></small>
                      </p>
                      <v-container fluid>
                        <ul class="text-start mb-4">
                          <li class="mb-4" v-for="(estrategia, estrategiaIndex) in project.estrategias" :key="estrategiaIndex">
                            <div class="form-chip mb-1">{{ estrategia.ejeEstrategico.nombreEje }}</div>
                            <ul style="padding-bottom: 0;">
                              <li style="margin-left:1em"><div class="form-chip mb-1">{{ estrategia.areaMisional.nombreArea }}</div>
                                <ul style="padding-bottom: 0;">
                                  <li style="margin-left:1em">
                                    <div class="form-chip">{{ estrategia.name }}</div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </v-container>
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start">
                        <small><strong>Nombre Encargado: </strong></small>
                        <span>{{ project.nombreEncargado }}</span>
                      </p>
                      <p class="text-start">
                        <small><strong>Correo Encargado: </strong></small>
                        <span>{{ project.emailEncargado }}</span>
                      </p>
                      <p class="text-start">
                        <small><strong>Anexo</strong></small>
                        <br>
                        <span>{{ project.anexoEncargado }}</span>
                      </p>
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start">
                        <small><strong>Problematica</strong></small>
                        <br>
                        <span>{{ project.problematica }}</span>
                      </p>
                    </v-col>
                    <v-col class="ma-0 pa-0"
                           cols="12"
                           xs="12"
                           sm="12"
                           md="6"
                           lg="6"
                           xl="6"
                    >
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start">
                        <small><strong class="mr-2">Objetivo general:</strong></small>
                        <span>{{ project.objetivoGeneral }}</span>
                      </p>
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start">
                        <small><strong>Objetivos específicos</strong></small>
                      </p>
                      <ul class="text-start mb-2">
                        <li>
                          {{ project.objetivoEspecifico1 }}
                        </li>
                        <li>
                          {{ project.objetivoEspecifico2 }}
                        </li>
                        <li>
                          {{ project.objetivoEspecifico3 }}
                        </li>
                      </ul>
                      <v-divider class="mb-2"></v-divider>
                      <p class="text-start">
                        <small><strong>Resumen</strong></small>
                        <br>
                        <span>{{ project.resumen }}</span>
                      </p>
                      <p class="text-start">
                        <small><strong>Impacto</strong></small>
                        <br>
                        <span>{{ project.impacto }}</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
              <milestones-component :milestones="milestones" :project="project"
                                    :missingFields="missingFields" class="mb-6"/>
              <expense-tab-component :expenses="expenses" :project="project" :missingFields="missingFields"/>
            </v-col>
            <v-divider></v-divider>
          </v-container>
          <div class="welcome-link-group mb-8">
            <v-btn style="background-color: #EA7730; color: white"
                   dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <VueHtml2pdf v-if="this.project != null"
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename=this.project.nombre
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="A4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf">
      <section slot="pdf-content">
        <div class="container-pdf d-none" style="font-size:8px;">
          <div class="title-pdf">FORMULARIO DE PRESENTACION DE PROYECTO</div>
          <div class="container">
            <!-- top right bottom left -->
            <table border="0" cellspacing="0" cellpadding="0" width="100%">
              <thead>
                <tr>
                  <th colspan="2" style="text-align: left; padding: 10px; font-size: 10px">Información de Proyectos</th>
                </tr>
              </thead>
              <colgroup>
                <col class="col-2" />
                <col class="col-10" />
              </colgroup>
              <tbody>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">Nombre del Proyecto: </td>
                  <td style="border: 1px solid gray; border-left: 0px; padding-left: 10px">{{project.nombre}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Fecha de creacion: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{ this.formatDate(project.createdAt) }}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Eje Estratégico/ Area Misional/ Estrategia
                  </td>
                  <td colspan="2" style="border: 1px solid gray; border-top: 0px; border-left: 0px">
                    <ul class="text-start mb-4">
                      <li class="mb-4" v-for="(estrategia, estrategiaIndex) in project.estrategias" :key="estrategiaIndex">
                        <div class="form-chip mb-1">{{ estrategia.ejeEstrategico.nombreEje }}</div>
                        <ul style="padding-bottom: 0;">
                          <li style="margin-left:1em"><div class="form-chip mb-1">{{ estrategia.areaMisional.nombreArea }}</div>
                            <ul style="padding-bottom: 0;">
                              <li style="margin-left:1em">
                                <div class="form-chip">{{ estrategia.name }}</div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Unidad Academica encargada de la ejecución: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.centroCosto.ccNombre}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Unidades Academicas en alianza (opcional): </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">
                    <ul class="text-start mb-12">
                      <li class="mb-12" v-for="(aliadas, aliadasIndex) in project.unidadesAliadas" :key="aliadasIndex">
                        <div class="form-chip">{{ aliadas.ccNombre }}</div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Nombre Encargado: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.nombreEncargado}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Correo Encargado: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.emailEncargado}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Anexo Encargado: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.anexoEncargado}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Problematica: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.problematica.substring(0,300)}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Objetivo General: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.objetivoGeneral}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Objetivos Específicos: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">
                    <ul class="text-start mb-12">
                      <li class="mb-12">
                        <div class="form-chip">{{ this.project.objetivoEspecifico1 }}</div>
                      </li><li class="mb-12">
                      <div class="form-chip">{{ this.project.objetivoEspecifico2 }}</div>
                    </li><li class="mb-12">
                      <div class="form-chip">{{ this.project.objetivoEspecifico3 }}</div>
                    </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Resumen del Proyecto: </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.resumen}}</td>
                </tr>
                <tr>
                  <td style="padding: 20px 0px 20px 5px; border: 1px solid gray; border-top: 0px">Impacto esperado y principales beneficiados del proyecto (directo e indirectos) : </td>
                  <td style="border: 1px solid gray; border-top: 0px;border-left: 0px; padding-left: 10px">{{project.impacto}}</td>
                </tr>
              </tbody>
            </table>
            <div class="html2pdf__page-break"/>
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="margin-top: 50px">
              <thead>
              <tr>
                <th colspan="2" style="text-align: left; padding: 10px; font-size: 10px">Información de Hitos</th>
              </tr>
              <tr>
                <th>Hito</th>
                <th>Actividad</th>
                <th>Mes inicio</th>
                <th>Mes termino</th>
              </tr>
              </thead>
              <colgroup>
                <col class="col-4" />
                <col class="col-4" />
                <col class="col-2" />
                <col class="col-2" />
              </colgroup>
              <tbody>
              <tr v-for="(hito, hitosIndex) in milestones" :key="hitosIndex">
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ hito.nombre }}</td>
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ hito.actividad }}</td>
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ hito.mes_inicio.name }}</td>
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ hito.mes_termino.name }}</td>
              </tr>
              </tbody>
            </table>
            <div class="html2pdf__page-break"/>
            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="margin-top: 50px">
              <thead>
              <tr>
                <th colspan="2" style="text-align: left; padding: 10px; font-size: 10px">Información de Gastos</th>
              </tr>
              <tr>
                <th>Item</th>
                <th>Descripcion</th>
                <th>Monto</th>
              </tr>
              </thead>
              <colgroup>
                <col class="col-4" />
                <col class="col-6" />
                <col class="col-2" />
              </colgroup>
              <tbody>
              <tr v-for="(gasto, gastosIndex) in expenses" :key="gastosIndex">
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ gasto.nombre }}</td>
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ gasto.descripcion }}</td>
                <td style="padding: 20px 0px 20px 5px; border: 1px solid gray;">{{ gasto.monto }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import Select from '@/components/form/Select.vue';
import Milestones from '@/components/stages/Milestones.vue';
import ExpenseTab from '@/components/stages/ExpenseTab.vue';
import StepComponent from '@/components/steps/Stepper.vue';
import Swal from 'sweetalert2';
import VueHtml2pdf from 'vue-html2pdf'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'application-form',
  components: {
    'VueHtml2pdf': VueHtml2pdf,
    'select-component': Select,
    'milestones-component': Milestones,
    'expense-tab-component': ExpenseTab,
    'steps-component': StepComponent
  },
  data() {
    return {
      files: [],
      projectFile: [],
      projects: [],
      project: null,
      observation: '',
      milestones: [],
      missingFields: true,
      aprobacion: false,
      expenses: [],
      loadingAdd: false,
      recursos_asignados_proyecto: null,
      financiamiento_asignado: null,
      allFieldsMilestoneComplete: false,
      allFieldsItemGastoComplete: false,
      months: [
        {
          name: "Seleccione",
          number: 0,
        },
        {
          name: 'Enero',
          number: 1,
        },
        {
          name: 'Febrero',
          number: 2,
        },
        {
          name: 'Marzo',
          number: 3,
        },
        {
          name: 'Abril',
          number: 4,
        },
        {
          name: 'Mayo',
          number: 5,
        },
        {
          name: 'Junio',
          number: 6,
        },
        {
          name: 'Julio',
          number: 7,
        },
        {
          name: 'Agosto',
          number: 8,
        },
        {
          name: 'Septiembre',
          number: 9,
        },
        {
          name: 'Octubre',
          number: 10,
        },
        {
          name: 'Noviembre',
          number: 11,
        },
        {
          name: 'Diciembre',
          number: 12,
        },
      ],
    }
  },
  created() {
    this.getAllProyecto(this.info.ccValor)
      .then(response => {
        this.projects = response.data.response;
        if(this.getSelectedProject() !== null &&  this.getSelectedProject() > 0){
          this.project = this.projects.find(project => project.id === this.getSelectedProject())
        }else{
          this.project = this.projects[this.projects.length - 1];
        }
      })
      .catch(err => console.log(err));
    this.getTotalItemGasto(this.info.ccValor)
      .then(response => {
        this.financiamiento_asignado = response.data.response;
      })
      .catch(err => console.log(err));
  },
  methods: {
    ...mapActions('main', [
      'getAllProyecto',
      'getTotalItemGasto',
      'getTotalItemGastoProyecto',
      'getHitoByIdProyecto',
      'getItemGastoByProyecto',
      'updateRevisionProyecto',
      'saveObservacionProyecto',
      'getObservacionProyecto',
      'downloadDocumentoProject',
      'uploadDocumentoProject'
    ]),
    formatDate(stringDate){
        let date = new Date(Date.parse(stringDate));
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        let minute = date.getMinutes();
        return '' + (day <= 9 ? '0' + day : day) + '/' + (month<=9 ? '0' + month : month) + '/' + year + ' ' + (hour<=9 ? '0'+hour:hour) + ':' + (minute<=9 ? '0'+minute:minute);
    },
    checkAllRequiredFieldsAreCompleted(){
      let excludeFields = ["unidadesAliadas", "objetivoEspecifico2", "objetivoEspecifico3"];
      let emptyFieldOnProject = [];
      if(this.project != null){
        Object.keys(this.project).forEach(key => {
          if(key !== undefined && this.project[key] != null
              && this.project[key].length === 0 && !excludeFields.includes(key.toString())){
            emptyFieldOnProject.push(key);
          }
        });

        console.log(emptyFieldOnProject);

        if(emptyFieldOnProject.length > 0)
          this.missingFields = true;
        else
          this.missingFields = false;
      }else{
        this.missingFields = true;
      }
    },
    print(){
      this.$refs.html2Pdf.generatePdf()
    },
    sentProject() {
      Swal.fire({
        title: 'Se enviará el proyecto a revisión, una vez enviado no puede modificar el proyecto',
        text: "Deseas continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#EA7600',
        cancelButtonColor: '#394049',
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateRevisionProyecto(this.project.id)
            .then(() => {
              this.project.enviadoRevision = true;
              this.projects = this.projects.map(e => {
                if (e.id == this.project.id) {
                  e.enviadoRevision = true;
                }
                return e;
              });
              Swal.fire({
                title: 'Proyecto enviado a revisión.',
                confirmButtonColor: '#EA7600',
                confirmButtonText: 'Sí',
                icon: 'success',
              })
            })
            .catch(err => console.log(err));
        }
      })
    },
    sentObservation() {
      this.saveObservacionProyecto({
        idProject: this.project.id,
        observacion: this.observation
      })
        .then(() => {
          // alert('Observación editada.')
          Swal.fire({
            title: 'Observaciones',
            text: 'Edición completada',
            confirmButtonColor: '#EA7600',
            confirmButtonText: 'Aceptar',
            icon: 'success',
          });
        })
        .catch(err => console.log(err));
    },
    changeProject(value) {
      this.project = value;
      if(this.project) {
        this.setSelectedProject(this.project.id);
        this.aprobacion = false;
        this.setProjectData(this.project);
        this.checkAllRequiredFieldsAreCompleted();
      }
    },
    setProjectData(project) {
      if (project == null) {
        return;
      }
      this.milestones = [];
      this.expenses = [];
      this.observation = '';
      this.recursos_asignados_proyecto = null;
      if (project) {
        this.getObservacionProyecto(project.id)
          .then(response => {
            if (response.data.response && response.data.response.length != 0) {
              this.observation = response.data.response.descripcion;
            }
          })
          .catch(err => console.log(err));
        this.getTotalItemGastoProyecto(project.id)
          .then(response => {
            this.recursos_asignados_proyecto = response.data.response;
          })
          .catch(err => console.log(err));
        this.getHitoByIdProyecto(project.id)
          .then(response => {
            response.data.response.forEach(milestone => {
              if(milestone.mesInicio === "Seleccione" || milestone.mesTermino === "Seleccione" || milestone.nombreHito === "" || milestone.actividades === "")
                this.allFieldsMilestoneComplete = false;
              else
                this.allFieldsMilestoneComplete = true;

              this.milestones.push({
                id: milestone.id,
                nombre: milestone.nombreHito,
                actividad: milestone.actividades,
                mes_inicio: this.months.find(e => e.name == milestone.mesInicio),
                mes_termino: this.months.find(e => e.name == milestone.mesTermino),
                ready: true
              });
            });
          })
          .catch(err => console.log(err));
        this.getItemGastoByProyecto(project.id)
          .then(response => {
            response.data.response.forEach(expense => {

              if(expense.nombreItem === "" || expense.descripcionItem === "")
                this.allFieldsItemGastoComplete = false;
              else
                this.allFieldsItemGastoComplete = true;

              this.expenses.push({
                id: expense.id,
                nombre: expense.nombreItem,
                descripcion: expense.descripcionItem,
                monto: expense.montoItem,
              });
            });
          })
          .catch(err => console.log(err));
      }
    },
    uploadImages() {
      document.getElementById('input-user-images').click()
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files
      if(!droppedFiles) return
      this.saveFiles(droppedFiles)
    },
    imageFunction() {
      let inputFiles = this.$refs.inputFile.files
      this.saveFiles(inputFiles)
    },
    saveFiles(files) {
      this.files = []
      for (let inputFile of files) {
        let reader = new FileReader()
        let tempFile = {
          url: '',
          document: ''
        }
        reader.addEventListener("load", function() {
          tempFile.url = reader.result
        }.bind(this), false)
        if(inputFile) {
          if ( /\.(pdf)$/i.test(inputFile.name) ) {
            reader.readAsDataURL(inputFile);
            tempFile.document = inputFile
            this.files.push(tempFile)
          } else {
            // the image format is not supported
            // image is not png | jpg | jpeg
            // not added
          }
        }
      }
    },
    sendFile() {
      if (!(this.files && this.files.length !== 0)) {
        Swal.fire({
          title: 'Por favor selecciona un archivo.',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Volver',
          icon: 'warning',
        })
        return;
      }
      this.uploadDocumentoProject({
        idCentroCosto: this.info.id,
        id: this.project.id,
        file: this.files[0]
      })
        .then(response => {
          this.project.approvedAt = response.data.response.approvedAt;
          Swal.fire({
            title: 'Archivo envíado.',
            confirmButtonColor: '#EA7600',
            confirmButtonText: 'Volver',
            icon: 'success',
          });
          this.files = [];
        })
        .catch(err => {
          console.log(err);
          this.files = [];
        });
    },
    downloadFile() {
      this.downloadDocumentoProject(this.project.id)
        .then(response => {
          this.projectFile = [];
          this.projectFile.push(response.data.response);
        })
        .catch(err => {
          console.log(err);
          this.projectFile = [];
        });
    },
    download(data){
      const {content, filename, mime} = data;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    },
    generateDownloadFile(){
      this.download(this.projectFile[0]);
    },
    formatMoney(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    setSelectedProject(projectId){
      localStorage.setItem('idSelectedProject', projectId)
    },
    getSelectedProject(){
      return parseInt(localStorage.getItem('idSelectedProject'));
    }
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    }),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    }
  },
  watch: {
    // aprobacion: function(newVal, oldVal) {
    //   console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    // }
  }
}
</script>

<style scoped>
.container-pdf {
  margin: 50px;
  border: 1px solid gray;
}
.title-pdf{
  padding: 10px;
  text-align: center;
  background-color: aquamarine;
}

.border-b {
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.content {
  background-color: white;
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.web-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.web-grid-profile {
  @media only screen and (min-width: $mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: $mobile - 1px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.web-grid div::before,
.web-grid-profile div::before {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.empty-file-text {
  font-size: 12px;
  color: #2e2c2c;
}

.border {
  border: 1px solid #dedede;
}

.dropzone-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.square-background {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 12px;
}

.form-chip {
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 10px;
}
</style>